.texty {
  position: relative;
}
.texty span {
  display: inline-block;
  white-space: pre-wrap;
}
.texty.mask-bottom,
.texty.mask-top {
  overflow: hidden;
}
